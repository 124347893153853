<script>

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import TinyMCEEditor from "@/components/tinymce";
import FileManager from "@/views/apps/filemanager";
import {commonService} from "@/helpers/common.service";
import {emojiCategoryService} from "@/helpers/blogservice/emoji.category.service";

export default {
  page: {
    title: "Add/Change Category",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      title: "Emoji Category",
      items: [
        {
          text: "Emoji",
          href: "/",
        },
        {
          text: "Category",
          active: true,
        },
      ],
      requestData: {
        title: '',
        description: '',
        slug: '',
        seo: {
          title: '',
          description: '',
          keyword: ''
        },
        media: {
          file_id: 0,
          url: '',
          type: 1
        },
        content: '',
        id: '',
        parent_id: 0,
        status: 1
      },
      allCategories: [],
      siteDomain: process.env.VUE_APP_EMOJI_URL,
    };
  },
  setup() {

  },
  mounted() {
    if (this.$route.params && this.$route.params.id) {
      this.requestData.id = this.$route.params.id;
    }
    this.onGet();
  },
  computed: {

  },
  components: {
    FileManager,
    TinyMCEEditor,
    Layout,
    PageHeader
  },
  methods: {
    onSelectFile(result) {
      this.requestData.media = {
        file_id: result.id,
        url: result.fullUrl,
        type: 1
      };
    },
    openFileManagerPopup() {
      this.$refs.fileManager.onOpenFileManager();
    },
    generateSlugFromString() {
      if (this.requestData.title && !this.requestData.id) {
        this.requestData.slug = commonService.generateSlug(this.requestData.title);
      }
    },
    async onGet() {
      let response = await emojiCategoryService.get({id: this.requestData.id});
      if (response?.data?.status) {
        const category = response.data.data.model;
        if (category) {
          this.requestData.title = category?.title ?? '';
          this.requestData.description = category?.description ?? '';
          this.requestData.slug = category?.slug ?? '';
          this.requestData.content = category?.content ?? '';
          this.requestData.parent_id = category?.parent_id ?? 0;
          this.requestData.status = category?.status ?? 1;
          this.requestData.media = category?.media ?? {
            file_id: 0,
            url: '',
            type: 1
          };
          this.requestData.seo = category?.seo ?? {
            title: '',
            description: '',
            keyword: ''
          };
        }
        this.allCategories = response?.data?.data?.categories;
      }
      this.$refs.tinyMceBlogCategory.reloadContent(this.requestData.content);
    },
    async changeStatus(id, status) {
      await emojiCategoryService.statusChange({
        id, status
      });
      await this.onGet();
    },
    async onSave() {
      if (this.requestData.id) {
        let response = await emojiCategoryService.update(this.requestData);
        if (response?.data?.status) {
          await this.$router.push({ name: 'emojiCategory' })
        }
      } else {
        let response = await emojiCategoryService.add(this.requestData);
        if (response?.data?.status) {
          await this.$router.push({ name: 'emojiCategory' })
        }
      }
    },
    onChangeContent(content) {
      this.requestData.content = content;
    },
    onParentSelect() {

    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Add a category</h5>
              <div class="flex-shrink-0 hstack gap-2">
                <button type="button" class="btn btn-info mr-5" @click="onSave">
                  <i class="las la-save"></i> Save
                </button>
                <router-link to="/emoji/category" class="btn btn-danger">
                  <i class="las la-times"></i> Cancel
                </router-link>
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12 col-md-9">

                <div class="row gy-4 mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="parentId" class="form-label">Parent</label>
                    <select class="form-control" name="parentId" id="parentId" v-model="requestData.parent_id">
                      <option value="0">Root</option>
                      <option v-for="item of allCategories" :key="item.id" :value="item.id">{{ item.text }}</option>
                    </select>
                  </div>
                </div>

                <div class="row gy-4 mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="titleInput" class="form-label">Title</label>
                    <input
                        @input="event => requestData.title = event.target.value"
                        :value="requestData.title"
                        @change="generateSlugFromString"
                        type="text"
                        class="form-control"
                        id="titleInput">
                  </div>
                </div>

                <div class="row gy-4 mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="slugInput" class="form-label">Slug</label>
                    <input
                        @input="event => requestData.slug = event.target.value"
                        :value="requestData.slug"
                        type="text"
                        class="form-control"
                        id="slugInput">
                  </div>
                </div>

                <div class="row gy-4  mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="seoTitleInput" class="form-label">Seo Title</label>
                    <input
                        @input="event => requestData.seo.title = event.target.value"
                        :value="requestData.seo.title"
                        type="text"
                        class="form-control"
                        id="seoTitleInput">
                  </div>
                </div>

                <div class="row gy-4  mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="seoDescInput" class="form-label">Seo Description</label>
                    <textarea
                        @input="event => requestData.seo.description = event.target.value"
                        :value="requestData.seo.description"
                        type="text"
                        class="form-control"
                        id="seoDescInput"></textarea>
                  </div>
                </div>
                <div class="row gy-4  mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="seoKeywordInput" class="form-label">Seo Keyword</label>
                    <textarea
                        @input="event => requestData.seo.keyword = event.target.value"
                        :value="requestData.seo.keyword"
                        type="text"
                        class="form-control"
                        id="seoKeywordInput"></textarea>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-3">
                <!-- Responsive Images -->
                <b-img :src="requestData.media.url ? requestData.media.url : require('@/assets/images/default-img.jpg')" class="img-fluid" role="button" alt="Avatar Url" @click="openFileManagerPopup"> </b-img>

                <div class="row mt-5">
                  <div class="col-6">
                    <a target="_blank" :href="siteDomain + requestData.slug"><i class="las la-external-link-square-alt"></i> Open Category</a>
                  </div>
                  <div class="col-6">
                    <div class="hstack gap-3 flex-wrap">
                      <button
                          @click="changeStatus(requestData.id, 2)"
                          v-if="requestData.status === 1 || requestData.status === 4"
                          type="button"
                          class="btn btn-sm btn-success">Publish</button>
                      <button
                          @click="changeStatus(requestData.id, 4)"
                          v-if="requestData.status === 2"
                          type="button"
                          class="btn btn-sm btn-light btn-success">UnPublish</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="ckeditor-classic">
                  <TinyMCEEditor
                      :content="requestData.content"
                      :contentChanged="onChangeContent"
                      :id="'blog-category-add'"
                      ref="tinyMceBlogCategory"
                  />
                </div>
              </div>
            </div>

          </div>
          <!-- end card-body -->

          <div class="card-footer text-muted">
            <div class="flex-shrink-0 hstack gap-2">
              <button type="button" class="btn btn-info mr-5" @click="onSave">
                <i class="las la-save"></i> Save
              </button>
              <router-link to="/emoji/category" class="btn btn-danger">
                <i class="las la-times"></i> Cancel
              </router-link>
            </div>
          </div>

        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <FileManager
        ref="fileManager"
        :onSelectedFunc="onSelectFile"
        :id="'blogCategoryFileManagerModalId'"
    />

  </Layout>
</template>
