import {getInstance} from "@/helpers/axios.service";
import {endPoint} from "@/helpers/Endpoint";

export const emojiCategoryService = {
    get, gets, add, update, statusChange
};

async function get(request = {}) {
    return await getInstance(endPoint.emojiCategoryGet, request);
}

async function gets(request = {}) {
    return await getInstance(endPoint.emojiCategoryGets, request);
}

async function add(request = {}) {
    return await getInstance(endPoint.emojiCategoryAdd, request);
}

async function update(request = {}) {
    return await getInstance(endPoint.emojiCategoryUpdate, request);
}

async function statusChange(request = {}) {
    return await getInstance(endPoint.emojiCategoryStatusChange, request);
}
